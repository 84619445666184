import * as tslib_1 from "tslib";
import { GestureConfig } from '@angular/material';
var AppHammerGestureConfig = /** @class */ (function (_super) {
    tslib_1.__extends(AppHammerGestureConfig, _super);
    function AppHammerGestureConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppHammerGestureConfig.prototype.buildHammer = function (element) {
        return new GestureConfig({ touchAction: 'pan-y' }).buildHammer(element);
    };
    return AppHammerGestureConfig;
}(GestureConfig));
export { AppHammerGestureConfig };
