import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { HttpErrorHandler } from '@common/core/http/errors/http-error-handler.service';
import { transformAngularUploadEvent } from '@common/uploads/utils/upload-progress-event';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./errors/http-error-handler.service";
var AppHttpClient = /** @class */ (function () {
    function AppHttpClient(httpClient, errorHandler) {
        this.httpClient = httpClient;
        this.errorHandler = errorHandler;
    }
    AppHttpClient.prototype.get = function (uri, params, options) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (options === void 0) { options = {}; }
        var httpParams = this.generateHttpParams(params);
        return this.httpClient.get(this.prefixUri(uri), tslib_1.__assign({ params: httpParams }, options))
            .pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.post = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = null; }
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.put = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        params = this.spoofHttpMethod(params, 'PUT');
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.delete = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        params = this.spoofHttpMethod(params, 'DELETE');
        return this.httpClient.post(this.prefixUri(uri), params)
            .pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.postWithProgress = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        var req = new HttpRequest('POST', this.prefixUri(uri), params, {
            reportProgress: true
        });
        var now = Date.now();
        return this.httpClient.request(req).pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }), map(function (e) { return transformAngularUploadEvent(e, now); }), filter(function (e) { return !!e; }));
    };
    /**
     * Prefix specified uri with backend API prefix.
     */
    AppHttpClient.prototype.prefixUri = function (uri) {
        if (uri.indexOf('://') > -1)
            return uri;
        return AppHttpClient.prefix + '/' + uri;
    };
    /**
     * Generate http params for GET request.
     */
    AppHttpClient.prototype.generateHttpParams = function (params) {
        var httpParams = new HttpParams();
        if (!params)
            return httpParams;
        Object.keys(params).forEach(function (key) {
            var value = params[key];
            if (value == null)
                value = '';
            httpParams = httpParams.append(key, value);
        });
        return httpParams;
    };
    /**
     * Spoof http method by adding '_method' to request params.
     */
    AppHttpClient.prototype.spoofHttpMethod = function (params, method) {
        if (params instanceof FormData) {
            params.append('_method', method);
        }
        else {
            params['_method'] = method;
        }
        return params;
    };
    AppHttpClient.prefix = 'secure';
    AppHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppHttpClient_Factory() { return new AppHttpClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpErrorHandler)); }, token: AppHttpClient, providedIn: "root" });
    return AppHttpClient;
}());
export { AppHttpClient };
