import { HttpCacheClient } from '../../http/http-cache-client';
import * as i0 from "@angular/core";
import * as i1 from "../../http/http-cache-client";
export var CUSTOM_PAGE_URI = 'page';
var Pages = /** @class */ (function () {
    function Pages(http) {
        this.http = http;
    }
    Pages.prototype.getAll = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.getWithCache("" + CUSTOM_PAGE_URI, params);
    };
    Pages.prototype.get = function (id) {
        return this.http.getWithCache(CUSTOM_PAGE_URI + "/" + id);
    };
    Pages.prototype.create = function (params) {
        return this.http.post("" + CUSTOM_PAGE_URI, params);
    };
    Pages.prototype.update = function (id, params) {
        return this.http.put(CUSTOM_PAGE_URI + "/" + id, params);
    };
    Pages.prototype.delete = function (ids) {
        return this.http.delete(CUSTOM_PAGE_URI + "/" + ids);
    };
    Pages.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Pages_Factory() { return new Pages(i0.ɵɵinject(i1.HttpCacheClient)); }, token: Pages, providedIn: "root" });
    return Pages;
}());
export { Pages };
