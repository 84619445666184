import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PlayerState } from '../state/player-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from '../state/player-state-actions';
import { LazyLoaderService } from '@common/core/utils/lazy-loader.service';
import { OverlayPanelRef } from '@common/core/ui/overlay-panel/overlay-panel-ref';
import { BreakpointsService } from '@common/core/ui/breakpoints.service';
import { Settings } from '@common/core/config/settings.service';
var PlayerComponent = /** @class */ (function () {
    function PlayerComponent(store, sanitizer, overlayRef, breakpoints, lazyLoader, settings) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.overlayRef = overlayRef;
        this.breakpoints = breakpoints;
        this.lazyLoader = lazyLoader;
        this.settings = settings;
        this.loading$ = new BehaviorSubject(false);
        this.iframeUrl$ = new BehaviorSubject(null);
        this.minimized = false;
        this.trackByFn = function (i, video) { return video.id; };
    }
    PlayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new PlayerOverlayOpened());
        this.activeVideo$.subscribe(function (video) {
            if (_this.shouldUsePlyr(video)) {
                _this.loadPlyr(video);
                _this.iframeUrl$.next(null);
            }
            else {
                _this.plyr && _this.plyr.stop();
                if (video.type === 'external') {
                    _this.iframeUrl$.next(null);
                    window.open(video.url, '_blank');
                }
                else {
                    _this.iframeUrl$.next(_this.sanitizer.bypassSecurityTrustResourceUrl(video.url));
                }
            }
        });
        // hide sidebar on mobile
        if (this.breakpoints.isMobile$.value) {
            this.toggleSidebar();
        }
    };
    PlayerComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new PlayerOverlayClosed());
        this.plyr && this.plyr.destroy();
    };
    PlayerComponent.prototype.playVideo = function (video) {
        this.store.dispatch(new PlayVideo(video, video.title));
    };
    PlayerComponent.prototype.toggleSidebar = function () {
        this.store.dispatch(new ToggleSidebar());
    };
    PlayerComponent.prototype.close = function () {
        this.overlayRef.close();
    };
    PlayerComponent.prototype.isTabletOrMobile = function () {
        return this.breakpoints.isMobile$.value ||
            this.breakpoints.isTablet$.value;
    };
    PlayerComponent.prototype.shouldUsePlyr = function (video) {
        return video.type === 'video' ||
            (video.type === 'embed' && this.embedSupportedByPlyr(video.url));
    };
    PlayerComponent.prototype.loadPlyr = function (video) {
        var _this = this;
        if (this.plyr) {
            this.plyr.source = this.getPlyrSource(video);
            return new Promise(function (resolve) { return resolve(); });
        }
        else {
            this.loading$.next(true);
            return Promise.all([
                this.lazyLoader.loadAsset('js/plyr.min.js', { type: 'js' }),
                this.lazyLoader.loadAsset('css/plyr.min.css', { type: 'css' }),
            ]).then(function () {
                _this.loading$.next(false);
                _this.plyr = new Plyr(_this.plyrEl.nativeElement, { autoplay: true });
                _this.plyr.source = _this.getPlyrSource(video);
            });
        }
    };
    PlayerComponent.prototype.isYoutube = function (url) {
        return url.includes('youtube.com');
    };
    PlayerComponent.prototype.isVimeo = function (url) {
        return url.includes('vimeo.com');
    };
    PlayerComponent.prototype.embedSupportedByPlyr = function (url) {
        return this.isYoutube(url) || this.isVimeo(url);
    };
    PlayerComponent.prototype.getPlyrSource = function (video) {
        var _this = this;
        if (video.type === 'embed') {
            return {
                type: 'video',
                poster: video.thumbnail,
                sources: [{
                        src: video.url,
                        provider: this.isYoutube(video.url) ? 'youtube' : 'vimeo',
                    }],
            };
        }
        else {
            var tracks = (video.captions || []).map(function (caption, i) {
                return {
                    kind: 'captions',
                    label: caption.name,
                    srclang: caption.language,
                    src: caption.url ? caption.url : _this.settings.getBaseUrl() + 'secure/caption/' + caption.id,
                    default: i === 0,
                };
            });
            return {
                type: 'video',
                title: video.name,
                sources: [
                    { src: video.url },
                ],
                poster: video.thumbnail,
                tracks: tracks,
            };
        }
    };
    tslib_1.__decorate([
        Select(PlayerState.activeVideo),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "activeVideo$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.relatedVideos),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "relatedVideos$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.mediaItem),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "mediaItem$", void 0);
    tslib_1.__decorate([
        Select(PlayerState.sidebarOpen),
        tslib_1.__metadata("design:type", Observable)
    ], PlayerComponent.prototype, "sidebarOpen$", void 0);
    return PlayerComponent;
}());
export { PlayerComponent };
