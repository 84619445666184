import * as tslib_1 from "tslib";
import { Video } from '../../../../models/video';
import { DeleteVideo, UpdateVideo } from '../../../titles/state/title-actions';
import { Select, Store } from '@ngxs/store';
import { CurrentUser } from '@common/auth/current-user';
import { TitleState } from '../../../titles/state/title-state';
import { Observable } from 'rxjs';
import { CrupdateVideoModalComponent } from '../../crupdate-video-modal/crupdate-video-modal.component';
import { Modal } from '@common/core/ui/dialogs/modal.service';
var EditTitleVideoWidgetComponent = /** @class */ (function () {
    function EditTitleVideoWidgetComponent(store, currentUser, modal) {
        this.store = store;
        this.currentUser = currentUser;
        this.modal = modal;
    }
    Object.defineProperty(EditTitleVideoWidgetComponent.prototype, "shouldShow", {
        get: function () {
            return !this.canDeleteVideo() && !this.canEditVideo();
        },
        enumerable: true,
        configurable: true
    });
    EditTitleVideoWidgetComponent.prototype.deleteVideo = function () {
        this.store.dispatch(new DeleteVideo(this.video));
    };
    EditTitleVideoWidgetComponent.prototype.canDeleteVideo = function () {
        return this.video.user_id === this.currentUser.get('id') ||
            this.currentUser.hasPermission('videos.delete');
    };
    EditTitleVideoWidgetComponent.prototype.canEditVideo = function () {
        return this.video.user_id === this.currentUser.get('id') ||
            this.currentUser.hasPermission('videos.update');
    };
    EditTitleVideoWidgetComponent.prototype.openEditModal = function () {
        var _this = this;
        var episode = this.store.selectSnapshot(TitleState.episode), title = this.store.selectSnapshot(TitleState.title);
        this.modal.open(CrupdateVideoModalComponent, 
        // title should not be changeable when adding video from any specific title pages and
        // season/episode should not be changeable when adding video from specific episode page
        {
            video: this.video,
            title: title,
            hideTitleSelect: true,
            hideEpisodeSelect: !!episode,
            episode: episode ? episode.episode_number : null,
            season: episode ? episode.season_number : null,
        }).beforeClosed().subscribe(function (newVideo) {
            if (!newVideo)
                return;
            _this.store.dispatch(new UpdateVideo(newVideo));
        });
    };
    tslib_1.__decorate([
        Select(TitleState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], EditTitleVideoWidgetComponent.prototype, "loading$", void 0);
    return EditTitleVideoWidgetComponent;
}());
export { EditTitleVideoWidgetComponent };
