import * as tslib_1 from "tslib";
import { Video } from '../../../../models/video';
import { RateVideo } from '../../../titles/state/title-actions';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../../titles/state/title-state';
import { Observable } from 'rxjs';
var VideoRatingWidgetComponent = /** @class */ (function () {
    function VideoRatingWidgetComponent(store) {
        this.store = store;
    }
    VideoRatingWidgetComponent.prototype.rateVideo = function (video, rating) {
        this.store.dispatch(new RateVideo(video, rating));
    };
    tslib_1.__decorate([
        Select(TitleState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], VideoRatingWidgetComponent.prototype, "loading$", void 0);
    return VideoRatingWidgetComponent;
}());
export { VideoRatingWidgetComponent };
