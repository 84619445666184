import { throwError } from 'rxjs';
import { Translations } from '../../translations/translations.service';
import * as i0 from "@angular/core";
import * as i1 from "../../translations/translations.service";
var HttpErrorHandler = /** @class */ (function () {
    function HttpErrorHandler(i18n) {
        this.i18n = i18n;
    }
    /**
     * Handle http request error.
     */
    HttpErrorHandler.prototype.handle = function (response, uri) {
        var body = this.parseJson(response.error);
        var error = {
            uri: uri,
            messages: body.messages,
            type: 'http',
            status: response.status,
            originalError: new Error(response.message)
        };
        if (response.status === 403 || response.status === 401) {
            this.handle403Error(body);
        }
        return throwError(error);
    };
    /**
     * Parse JSON without throwing errors.
     */
    HttpErrorHandler.prototype.parseJson = function (json) {
        var original;
        if (typeof json !== 'string') {
            original = json;
        }
        else {
            try {
                original = JSON.parse(json);
            }
            catch (e) {
                original = this.getEmptyErrorBody();
            }
        }
        if (!original || !original.messages) {
            return this.getEmptyErrorBody();
        }
        Object.keys(original.messages).forEach(function (key) {
            var message = original.messages[key];
            original.messages[key] = Array.isArray(message) ? message[0] : message;
        });
        return original;
    };
    HttpErrorHandler.prototype.getEmptyErrorBody = function () {
        return { status: 'error', messages: {} };
    };
    HttpErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrorHandler_Factory() { return new HttpErrorHandler(i0.ɵɵinject(i1.Translations)); }, token: HttpErrorHandler, providedIn: "root" });
    return HttpErrorHandler;
}());
export { HttpErrorHandler };
