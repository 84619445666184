import * as tslib_1 from "tslib";
import { Bootstrapper } from '../common/core/bootstrapper.service';
import { Store } from '@ngxs/store';
import { SetRatings, SetWatchlist } from './site/lists/user-lists/state/user-lists-state-actions';
var AppBootstrapperService = /** @class */ (function (_super) {
    tslib_1.__extends(AppBootstrapperService, _super);
    function AppBootstrapperService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Handle specified bootstrap data.
     */
    AppBootstrapperService.prototype.handleData = function (encodedData) {
        var data = _super.prototype.handleData.call(this, encodedData);
        // set user watchlist
        this.injector.get(Store).dispatch(new SetWatchlist(data['watchlist']));
        // set user ratings
        this.injector.get(Store).dispatch(new SetRatings(data['ratings']));
        return data;
    };
    return AppBootstrapperService;
}(Bootstrapper));
export { AppBootstrapperService };
