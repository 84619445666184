import * as tslib_1 from "tslib";
import { UploadValidation } from './upload-validation';
import { prettyBytes } from '../../../core/utils/pretty-bytes';
var FileSizeValidation = /** @class */ (function (_super) {
    tslib_1.__extends(FileSizeValidation, _super);
    function FileSizeValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.i18n.t('Maximum file size is :number', { number: prettyBytes(+_this.params.maxSize) });
        return _this;
    }
    FileSizeValidation.prototype.fails = function (file) {
        return this.params.maxSize < file.size;
    };
    return FileSizeValidation;
}(UploadValidation));
export { FileSizeValidation };
