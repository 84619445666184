import * as tslib_1 from "tslib";
import { TitleState } from '../../state/title-state';
import { Select, Store } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
var CurrentNextEpisodePanelComponent = /** @class */ (function () {
    function CurrentNextEpisodePanelComponent(urls, store) {
        this.urls = urls;
        this.store = store;
    }
    Object.defineProperty(CurrentNextEpisodePanelComponent.prototype, "episodes$", {
        get: function () {
            return zip(this.currentEpisode$, this.nextEpisode$);
        },
        enumerable: true,
        configurable: true
    });
    CurrentNextEpisodePanelComponent.prototype.getEpisodeUrl = function (episode) {
        var series = this.store.selectSnapshot(TitleState.title);
        return this.urls.episode(series, episode.season_number, episode.episode_number);
    };
    tslib_1.__decorate([
        Select(TitleState.nextEpisode),
        tslib_1.__metadata("design:type", Observable)
    ], CurrentNextEpisodePanelComponent.prototype, "nextEpisode$", void 0);
    tslib_1.__decorate([
        Select(TitleState.currentEpisode),
        tslib_1.__metadata("design:type", Observable)
    ], CurrentNextEpisodePanelComponent.prototype, "currentEpisode$", void 0);
    return CurrentNextEpisodePanelComponent;
}());
export { CurrentNextEpisodePanelComponent };
