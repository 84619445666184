import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var BASE_URI = 'caption';
var CaptionService = /** @class */ (function () {
    function CaptionService(http) {
        this.http = http;
    }
    CaptionService.prototype.all = function () {
        return this.http.get(BASE_URI);
    };
    CaptionService.prototype.create = function (params) {
        return this.http.post(BASE_URI, params);
    };
    CaptionService.prototype.update = function (id, params) {
        return this.http.put(BASE_URI + "/" + id, params);
    };
    CaptionService.prototype.delete = function (ids) {
        return this.http.delete(BASE_URI + "/" + ids);
    };
    CaptionService.prototype.reorder = function (videoId, captionIds) {
        return this.http.post(BASE_URI + "/" + videoId + "/order", { ids: captionIds });
    };
    CaptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaptionService_Factory() { return new CaptionService(i0.ɵɵinject(i1.AppHttpClient)); }, token: CaptionService, providedIn: "root" });
    return CaptionService;
}());
export { CaptionService };
