import { ElementRef, Injector, TemplateRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { BreakpointsService } from '../breakpoints.service';
import { OverlayPanelRef } from './overlay-panel-ref';
import { OVERLAY_PANEL_DATA } from './overlay-panel-data';
import { FullscreenOverlayScrollStrategy } from './fullscreen-overlay-scroll-strategy';
import { filter } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "../breakpoints.service";
var DEFAULT_CONFIG = {
    hasBackdrop: true,
    closeOnBackdropClick: true,
    panelClass: 'overlay-panel',
};
var OverlayPanel = /** @class */ (function () {
    function OverlayPanel(overlay, breakpoints, injector) {
        this.overlay = overlay;
        this.breakpoints = breakpoints;
        this.injector = injector;
    }
    OverlayPanel.prototype.open = function (cmp, userConfig) {
        var config = Object.assign({}, DEFAULT_CONFIG, userConfig);
        var cdkConfig = {
            positionStrategy: this.getPositionStrategy(config),
            hasBackdrop: config.hasBackdrop,
            panelClass: config.panelClass,
            backdropClass: config.backdropClass,
            scrollStrategy: config.fullScreen ? new FullscreenOverlayScrollStrategy() : null,
            disposeOnNavigation: true,
        };
        if (config.width)
            cdkConfig.width = config.width;
        if (config.height)
            cdkConfig.height = config.height;
        var overlayRef = this.overlay.create(cdkConfig);
        var overlayPanelRef = new OverlayPanelRef(overlayRef);
        var portal = cmp instanceof TemplateRef ?
            new TemplatePortal(cmp, config.viewContainerRef, config.data) :
            new ComponentPortal(cmp, null, this.createInjector(config, overlayPanelRef));
        overlayRef.attach(portal);
        if (config.closeOnBackdropClick) {
            overlayRef.backdropClick().subscribe(function () { return overlayPanelRef.close(); });
            overlayRef.keydownEvents()
                .pipe(filter(function (e) { return e.keyCode === ESCAPE; }))
                .subscribe(function () { return overlayPanelRef.close(); });
        }
        return overlayPanelRef;
    };
    OverlayPanel.prototype.createInjector = function (config, dialogRef) {
        var injectionTokens = new WeakMap();
        injectionTokens.set(OverlayPanelRef, dialogRef);
        injectionTokens.set(OVERLAY_PANEL_DATA, config.data || null);
        return new PortalInjector(this.injector, injectionTokens);
    };
    OverlayPanel.prototype.getPositionStrategy = function (config) {
        if (config.positionStrategy) {
            return config.positionStrategy;
        }
        var position = this.breakpoints.isMobile$.value ?
            (config.mobilePosition || config.position) :
            config.position;
        if (config.origin === 'global' || this.positionIsGlobal(position)) {
            return this.getGlobalPositionStrategy(position);
        }
        else {
            return this.getConnectedPositionStrategy(position, config.origin);
        }
    };
    OverlayPanel.prototype.positionIsGlobal = function (position) {
        return position === 'center' || !Array.isArray(position);
    };
    OverlayPanel.prototype.getGlobalPositionStrategy = function (position) {
        if (position === 'center') {
            return this.overlay.position().global().centerHorizontally().centerVertically();
        }
        else {
            var global_1 = this.overlay.position().global();
            Object.keys(position).forEach(function (key) {
                global_1[key](position[key]);
            });
            return global_1;
        }
    };
    OverlayPanel.prototype.getConnectedPositionStrategy = function (position, origin) {
        return this.overlay.position()
            .flexibleConnectedTo(origin)
            .withPositions(position);
    };
    OverlayPanel.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverlayPanel_Factory() { return new OverlayPanel(i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i2.BreakpointsService), i0.ɵɵinject(i0.INJECTOR)); }, token: OverlayPanel, providedIn: "root" });
    return OverlayPanel;
}());
export { OverlayPanel };
