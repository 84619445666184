import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SearchState } from '../state/search-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { Reset } from '../state/search-state-actions';
import { ActivatedRoute } from '@angular/router';
var SearchPageComponent = /** @class */ (function () {
    function SearchPageComponent(store, route) {
        this.store = store;
        this.route = route;
    }
    SearchPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function () {
            // TODO: remove "setTimeout"
            setTimeout(function () {
                _this.store.dispatch(new ToggleGlobalLoader(false));
            });
        });
    };
    SearchPageComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new Reset());
    };
    tslib_1.__decorate([
        Select(SearchState.query),
        tslib_1.__metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "query$", void 0);
    tslib_1.__decorate([
        Select(SearchState.movies),
        tslib_1.__metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "movies$", void 0);
    tslib_1.__decorate([
        Select(SearchState.series),
        tslib_1.__metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "series$", void 0);
    tslib_1.__decorate([
        Select(SearchState.people),
        tslib_1.__metadata("design:type", Observable)
    ], SearchPageComponent.prototype, "people$", void 0);
    return SearchPageComponent;
}());
export { SearchPageComponent };
