import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { LoadPerson } from './state/person-state-actions';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
var PersonResolverService = /** @class */ (function () {
    function PersonResolverService(store, router) {
        this.store = store;
        this.router = router;
    }
    PersonResolverService.prototype.resolve = function (route) {
        var _this = this;
        return this.store.dispatch(new LoadPerson(+route.params.id))
            .pipe(catchError(function () {
            _this.router.navigateByUrl('/');
            return of(null);
        }), mergeMap(function (store) {
            return of({ seo: store.person.metaTags });
        }));
    };
    PersonResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PersonResolverService_Factory() { return new PersonResolverService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: PersonResolverService, providedIn: "root" });
    return PersonResolverService;
}());
export { PersonResolverService };
