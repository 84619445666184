import * as i0 from "@angular/core";
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
        if (this.localStorageAvailable()) {
            this.storage = localStorage;
        }
        else {
            this.storage = null;
        }
    }
    /**
     * Retrieve specified item from local storage.
     */
    LocalStorage.prototype.get = function (key, defaultValue) {
        if (!this.storage)
            return defaultValue;
        var value;
        try {
            value = JSON.parse(this.storage.getItem(key));
        }
        catch (e) {
            //
        }
        return value == null ? defaultValue : value;
    };
    /**
     * Store specified item in local storage.
     */
    LocalStorage.prototype.set = function (key, value) {
        if (!this.storage)
            return null;
        value = JSON.stringify(value);
        this.storage.setItem(key, value);
    };
    /**
     * Remove specified item from local storage.
     */
    LocalStorage.prototype.remove = function (key) {
        if (!this.storage)
            return null;
        this.storage.removeItem(key);
    };
    /**
     * Check if local storage is available.
     */
    LocalStorage.prototype.localStorageAvailable = function () {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        }
        catch (e) {
            return false;
        }
    };
    LocalStorage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorage_Factory() { return new LocalStorage(); }, token: LocalStorage, providedIn: "root" });
    return LocalStorage;
}());
export { LocalStorage };
