import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var BASE_URI = 'videos';
var VideoService = /** @class */ (function () {
    function VideoService(http) {
        this.http = http;
    }
    VideoService.prototype.create = function (payload) {
        return this.http.post(BASE_URI, payload);
    };
    VideoService.prototype.update = function (id, payload) {
        return this.http.put(BASE_URI + "/" + id, payload);
    };
    VideoService.prototype.delete = function (ids) {
        return this.http.delete(BASE_URI + "/" + ids);
    };
    VideoService.prototype.rate = function (id, rating) {
        return this.http.post(BASE_URI + "/" + id + "/rate", { rating: rating });
    };
    VideoService.prototype.approve = function (id) {
        return this.http.post(BASE_URI + "/" + id + "/approve");
    };
    VideoService.prototype.disapprove = function (id) {
        return this.http.post(BASE_URI + "/" + id + "/disapprove");
    };
    VideoService.prototype.report = function (id) {
        return this.http.post(BASE_URI + "/" + id + "/report");
    };
    VideoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoService_Factory() { return new VideoService(i0.ɵɵinject(i1.AppHttpClient)); }, token: VideoService, providedIn: "root" });
    return VideoService;
}());
export { VideoService };
