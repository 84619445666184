import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var ReviewService = /** @class */ (function () {
    function ReviewService(http) {
        this.http = http;
    }
    ReviewService.prototype.getAll = function (params) {
        return this.http.get('reviews', params);
    };
    ReviewService.prototype.create = function (params) {
        return this.http.post('reviews', params);
    };
    ReviewService.prototype.update = function (id, payload) {
        return this.http.put('reviews/' + id, payload);
    };
    ReviewService.prototype.delete = function (ids) {
        return this.http.delete('reviews', { ids: ids });
    };
    ReviewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReviewService_Factory() { return new ReviewService(i0.ɵɵinject(i1.AppHttpClient)); }, token: ReviewService, providedIn: "root" });
    return ReviewService;
}());
export { ReviewService };
