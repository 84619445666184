import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
var BreakpointsService = /** @class */ (function () {
    function BreakpointsService(breakpointObserver) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.isMobile$ = new BehaviorSubject(false);
        this.isTablet$ = new BehaviorSubject(false);
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile$.next(result.matches);
        });
        this.breakpointObserver.observe(Breakpoints.Tablet).subscribe(function (result) {
            _this.isTablet$.next(result.matches);
        });
    }
    BreakpointsService.prototype.observe = function (value) {
        return this.breakpointObserver.observe(value);
    };
    BreakpointsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreakpointsService_Factory() { return new BreakpointsService(i0.ɵɵinject(i1.BreakpointObserver)); }, token: BreakpointsService, providedIn: "root" });
    return BreakpointsService;
}());
export { BreakpointsService };
