import { Router } from '@angular/router';
import { Users } from '../auth/users.service';
import { ValueLists } from '../core/services/value-lists.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CurrentUser } from '../auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "../auth/users.service";
import * as i2 from "@angular/router";
import * as i3 from "../auth/current-user";
import * as i4 from "../core/services/value-lists.service";
import * as i5 from "../auth/auth.service";
var AccountSettingsResolve = /** @class */ (function () {
    function AccountSettingsResolve(users, router, currentUser, values, auth) {
        this.users = users;
        this.router = router;
        this.currentUser = currentUser;
        this.values = values;
        this.auth = auth;
    }
    AccountSettingsResolve.prototype.resolve = function (route) {
        var _this = this;
        return forkJoin(this.users.get(this.currentUser.get('id')).pipe(map(function (response) { return response.user; })), this.values.get(['timezones', 'countries', 'localizations'])).pipe(catchError(function () {
            _this.router.navigate([_this.auth.getRedirectUri()]);
            return EMPTY;
        }), mergeMap(function (response) {
            if (response) {
                return of({ user: response[0], selects: response[1] });
            }
            else {
                _this.router.navigate([_this.auth.getRedirectUri()]);
                return EMPTY;
            }
        }));
    };
    AccountSettingsResolve.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountSettingsResolve_Factory() { return new AccountSettingsResolve(i0.ɵɵinject(i1.Users), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.CurrentUser), i0.ɵɵinject(i4.ValueLists), i0.ɵɵinject(i5.AuthService)); }, token: AccountSettingsResolve, providedIn: "root" });
    return AccountSettingsResolve;
}());
export { AccountSettingsResolve };
