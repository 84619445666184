import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CurrentUser } from '../auth/current-user';
import { Settings } from '../core/config/settings.service';
import { AppearanceListenerService } from '../shared/appearance/appearance-listener.service';
import { CustomHomepage } from '../core/pages/shared/custom-homepage.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "../auth/auth.service";
import * as i3 from "@angular/router";
import * as i4 from "../core/config/settings.service";
import * as i5 from "../shared/appearance/appearance-listener.service";
import * as i6 from "../core/pages/shared/custom-homepage.service";
var GuestGuard = /** @class */ (function () {
    function GuestGuard(currentUser, auth, router, settings, listener, homepage) {
        this.currentUser = currentUser;
        this.auth = auth;
        this.router = router;
        this.settings = settings;
        this.listener = listener;
        this.homepage = homepage;
    }
    GuestGuard.prototype.canActivate = function (route, state) {
        // allow to view guest pages in appearance editor
        if (this.listener.active)
            return true;
        // user is not logged in
        if (!this.currentUser.isLoggedIn())
            return true;
        var redirectUri = this.auth.getRedirectUri(), fallbackRedirectUri = this.settings.get('vebto.auth.fallbackRedirectUri');
        if (redirectUri === '/' && this.homepage.isOnlyForGuests() && fallbackRedirectUri) {
            redirectUri = fallbackRedirectUri;
        }
        this.router.navigate([redirectUri]);
        return false;
    };
    GuestGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestGuard_Factory() { return new GuestGuard(i0.ɵɵinject(i1.CurrentUser), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.Settings), i0.ɵɵinject(i5.AppearanceListenerService), i0.ɵɵinject(i6.CustomHomepage)); }, token: GuestGuard, providedIn: "root" });
    return GuestGuard;
}());
export { GuestGuard };
