import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../../titles/state/title-state';
import { Observable } from 'rxjs';
import { getFaviconFromUrl } from '@common/core/utils/get-favicon-from-url';
import { ReportVideo } from '../../../titles/state/title-actions';
import { PlayVideo } from '../../../player/state/player-state-actions';
var TitleVideoTableComponent = /** @class */ (function () {
    function TitleVideoTableComponent(store) {
        this.store = store;
    }
    TitleVideoTableComponent.prototype.playVideo = function (video) {
        var mediaItem = this.store.selectSnapshot(TitleState.titleOrEpisode);
        if (video.type === 'external') {
            window.open(video.url, '_blank');
        }
        else {
            this.store.dispatch(new PlayVideo(video, mediaItem));
        }
    };
    TitleVideoTableComponent.prototype.getFavicon = function (url) {
        return getFaviconFromUrl(url);
    };
    TitleVideoTableComponent.prototype.reportVideo = function (video) {
        this.store.dispatch(new ReportVideo(video));
    };
    tslib_1.__decorate([
        Select(TitleState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], TitleVideoTableComponent.prototype, "loading$", void 0);
    tslib_1.__decorate([
        Select(TitleState.videos),
        tslib_1.__metadata("design:type", Observable)
    ], TitleVideoTableComponent.prototype, "videos$", void 0);
    return TitleVideoTableComponent;
}());
export { TitleVideoTableComponent };
