import { Routes } from '@angular/router';
import { HomepageListsResolverService } from './site/homepage/homepage-lists-resolver.service';
import { ContactComponent } from '@common/contact/contact.component';
import { AuthGuard } from '@common/guards/auth-guard.service';
import { HomepageHostComponent } from './site/homepage/homepage-host/homepage-host.component';
import { HomepageHostGuard } from './site/homepage/homepage-host-guard';
var ɵ0 = { name: 'homepage' }, ɵ1 = function () { return import("./admin/app-admin.module.ngfactory").then(function (m) { return m.AppAdminModuleNgFactory; }); }, ɵ2 = function () { return import("./../common/billing/billing.module.ngfactory").then(function (m) { return m.BillingModuleNgFactory; }); };
var routes = [
    { path: '', component: HomepageHostComponent, canActivate: [HomepageHostGuard], resolve: { api: HomepageListsResolverService }, data: ɵ0 },
    { path: 'admin', loadChildren: ɵ1, canLoad: [AuthGuard] },
    { path: 'billing', loadChildren: ɵ2 },
    { path: 'contact', component: ContactComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
