import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { PlayVideo } from '../../../player/state/player-state-actions';
import { Settings } from '@common/core/config/settings.service';
var MediaItemHeaderComponent = /** @class */ (function () {
    function MediaItemHeaderComponent(store, settings) {
        this.store = store;
        this.settings = settings;
        this.transparent = false;
    }
    Object.defineProperty(MediaItemHeaderComponent.prototype, "backgroundImage", {
        get: function () {
            if (this.backdrop) {
                return 'url(' + this.backdrop + ')';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaItemHeaderComponent.prototype, "noBackdrop", {
        get: function () {
            if (!this.backdrop) {
                return 'no-backdrop';
            }
        },
        enumerable: true,
        configurable: true
    });
    MediaItemHeaderComponent.prototype.playVideo = function (video) {
        var title = this.store.selectSnapshot(TitleState.title);
        this.store.dispatch(new PlayVideo(video, title));
    };
    tslib_1.__decorate([
        Select(TitleState.primaryVideo),
        tslib_1.__metadata("design:type", Observable)
    ], MediaItemHeaderComponent.prototype, "primaryVideo$", void 0);
    return MediaItemHeaderComponent;
}());
export { MediaItemHeaderComponent };
