import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { MEDIA_TYPE } from '../../../media-type';
import { TitleUrlsService } from '../../title-urls.service';
import { shareLinkSocially } from '../../../../../common/core/utils/share-link-socially';
import { Settings } from '../../../../../common/core/config/settings.service';
import { shareViaEmail } from '../../../../../common/core/utils/share-via-email';
import { copyToClipboard } from '../../../../../common/core/utils/copy-link-to-clipboard';
import { MESSAGES } from '../../../../toast-messages';
import { Translations } from '../../../../../common/core/translations/translations.service';
import { Toast } from '../../../../../common/core/ui/toast.service';
var TitlePrimaryDetailsPanelComponent = /** @class */ (function () {
    function TitlePrimaryDetailsPanelComponent(urls, settings, store, i18n, toast) {
        this.urls = urls;
        this.settings = settings;
        this.store = store;
        this.i18n = i18n;
        this.toast = toast;
    }
    TitlePrimaryDetailsPanelComponent.prototype.isEpisode = function () {
        return this.item.type === MEDIA_TYPE.EPISODE;
    };
    TitlePrimaryDetailsPanelComponent.prototype.isSeries = function () {
        return this.item.type === MEDIA_TYPE.TITLE && this.item.is_series;
    };
    TitlePrimaryDetailsPanelComponent.prototype.isMovie = function () {
        return this.item.type === MEDIA_TYPE.TITLE && !this.item.is_series;
    };
    TitlePrimaryDetailsPanelComponent.prototype.shareUsing = function (type) {
        var title = this.store.selectSnapshot(TitleState.title);
        var link = this.settings.getBaseUrl(true) + 'titles/' + title.id;
        if (type === 'mail') {
            var siteName = this.settings.get('branding.site_name');
            var subject = this.i18n.t('Check out this link on ') + siteName;
            var body = title.name + " - " + siteName + " - " + link;
            shareViaEmail(subject, body);
        }
        else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        }
        else {
            shareLinkSocially(type, link, title.name);
        }
    };
    TitlePrimaryDetailsPanelComponent.prototype.isReleased = function () {
        return new Date(this.item.release_date) < new Date();
    };
    tslib_1.__decorate([
        Select(TitleState.title),
        tslib_1.__metadata("design:type", Observable)
    ], TitlePrimaryDetailsPanelComponent.prototype, "title$", void 0);
    return TitlePrimaryDetailsPanelComponent;
}());
export { TitlePrimaryDetailsPanelComponent };
