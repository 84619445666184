import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadRelatedVideos, PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from './player-state-actions';
import { TitlesService } from '../../titles/titles.service';
import { MEDIA_TYPE } from '../../media-type';
import { Settings } from '@common/core/config/settings.service';
var PlayerState = /** @class */ (function () {
    function PlayerState(titles, settings) {
        this.titles = titles;
        this.settings = settings;
    }
    PlayerState.isOpen = function (state) {
        return state.isOpen;
    };
    PlayerState.activeVideo = function (state) {
        return state.activeVideo;
    };
    PlayerState.relatedVideos = function (state) {
        return state.relatedVideos;
    };
    PlayerState.mediaItem = function (state) {
        return state.mediaItem;
    };
    PlayerState.sidebarOpen = function (state) {
        return state.sidebarOpen;
    };
    PlayerState.prototype.playVideo = function (ctx, action) {
        var state = ctx.getState();
        // already have this video and title loaded
        if (state.activeVideo && state.activeVideo.id === action.video.id) {
            return;
        }
        // if playing different videos for same title, there's no need to replace media item
        var newState = { activeVideo: action.video };
        if (action.mediaItem) {
            newState.mediaItem = action.mediaItem;
        }
        ctx.patchState(newState);
        var videoType = this.settings.get('streaming.related_videos_type');
        // related videos panel will be hidden
        if (videoType === 'hide')
            return;
        // load related videos from other titles or other episodes from same title (if it's a series)
        var loadRelatedEpisodes = action.video.episode && this.settings.get('player.show_next_episodes');
        if (videoType === 'other_titles' || loadRelatedEpisodes) {
            ctx.dispatch(new LoadRelatedVideos());
            // show other videos from this title
        }
        else {
            var contentType_1 = this.settings.get('streaming.video_panel_content'), relatedVideos = action.mediaItem.videos.filter(function (video) {
                return contentType_1 === 'all' ||
                    (contentType_1 === 'short' && video.category !== 'full') ||
                    video.category === contentType_1;
            });
            ctx.patchState({ relatedVideos: relatedVideos });
        }
    };
    PlayerState.prototype.loadRelatedVideos = function (ctx) {
        var mediaItem = ctx.getState().mediaItem, video = ctx.getState().activeVideo;
        var params = {
            episode: video.episode,
            season: video.season,
            videoId: video.id,
        };
        mediaItem.type === MEDIA_TYPE.EPISODE ?
            params.titleId = mediaItem.title_id :
            params.titleId = mediaItem.id;
        return this.titles.getRelatedVideos(params).pipe(tap(function (response) {
            ctx.patchState({ relatedVideos: response.videos });
        }));
    };
    PlayerState.prototype.playerOverlayClosed = function (ctx) {
        return ctx.patchState({ isOpen: false });
    };
    PlayerState.prototype.playerOverlayOpened = function (ctx) {
        return ctx.patchState({ isOpen: true });
    };
    PlayerState.prototype.toggleSidebar = function (ctx, action) {
        var state = action.open === null ? !ctx.getState().sidebarOpen : action.open;
        return ctx.patchState({ sidebarOpen: state });
    };
    tslib_1.__decorate([
        Action(PlayVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, PlayVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playVideo", null);
    tslib_1.__decorate([
        Action(LoadRelatedVideos),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "loadRelatedVideos", null);
    tslib_1.__decorate([
        Action(PlayerOverlayClosed),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayClosed", null);
    tslib_1.__decorate([
        Action(PlayerOverlayOpened),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayOpened", null);
    tslib_1.__decorate([
        Action(ToggleSidebar),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ToggleSidebar]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "toggleSidebar", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "isOpen", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "activeVideo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "relatedVideos", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "mediaItem", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "sidebarOpen", null);
    PlayerState = tslib_1.__decorate([
        State({
            name: 'player',
            defaults: {
                relatedVideos: [],
                isOpen: false,
                sidebarOpen: true,
            }
        }),
        tslib_1.__metadata("design:paramtypes", [TitlesService,
            Settings])
    ], PlayerState);
    return PlayerState;
}());
export { PlayerState };
