import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadFilterOptions, LoadMoreTitles, ReloadTitles, UpdateFilters } from './browse-title-actions';
import { TitlesService } from '../../titles.service';
import { ValueLists } from '@common/core/services/value-lists.service';
import { Settings } from '@common/core/config/settings.service';
import { Router } from '@angular/router';
var BrowseTitleState = /** @class */ (function () {
    function BrowseTitleState(store, titles, valueLists, settings, router) {
        this.store = store;
        this.titles = titles;
        this.valueLists = valueLists;
        this.settings = settings;
        this.router = router;
    }
    BrowseTitleState_1 = BrowseTitleState;
    BrowseTitleState.titles = function (state) {
        return state.titles.data;
    };
    BrowseTitleState.loading = function (state) {
        return state.loading;
    };
    BrowseTitleState.canLoadMore = function (state) {
        return state.titles.current_page < state.titles.last_page;
    };
    BrowseTitleState.doesNotHaveResults = function (state) {
        // loaded titles from backend at least once
        return state.titles.data && !state.titles.data.length;
    };
    BrowseTitleState.anyFilterActive = function (state) {
        return Object.keys(state.filters).length > 0;
    };
    BrowseTitleState.countries = function (state) {
        return state.filterOptions.countries;
    };
    BrowseTitleState.languages = function (state) {
        return state.filterOptions.languages;
    };
    BrowseTitleState.genres = function (state) {
        return state.filterOptions.genres;
    };
    BrowseTitleState.certifications = function (state) {
        return state.filterOptions.certifications;
    };
    BrowseTitleState.filters = function (state) {
        return state.filters;
    };
    BrowseTitleState.queryParamsToFilters = function (params) {
        var formValues = {};
        var keys = ['genre', 'released', 'score', 'runtime'];
        Object.keys(params).forEach(function (key) {
            if (!params[key])
                return;
            if (keys.indexOf(key) > -1 && !Array.isArray(params[key])) {
                formValues[key] = params[key].split(',');
            }
            else if (params[key] === 'true') {
                formValues[key] = true;
            }
            else if (params[key] === 'false') {
                formValues[key] = false;
            }
            else {
                formValues[key] = params[key];
            }
        });
        return formValues;
    };
    BrowseTitleState.filtersToQueryParams = function (values) {
        var queryParams = {};
        Object.keys(values).forEach(function (key) {
            if (!values[key])
                return;
            queryParams[key] = Array.isArray(values[key]) ?
                values[key].join(',') :
                values[key];
        });
        return queryParams;
    };
    BrowseTitleState.prototype.reloadTitles = function (ctx, action) {
        var newFilters = BrowseTitleState_1.queryParamsToFilters(action.params);
        ctx.patchState({
            loading: true,
            filters: newFilters,
        });
        // apply specified filters as query params to current url
        this.router.navigate([], { queryParams: BrowseTitleState_1.filtersToQueryParams(newFilters), replaceUrl: true });
        return this.titles.getAll(tslib_1.__assign({}, action.params, { perPage: 16 })).pipe(tap(function (response) {
            ctx.patchState({
                titles: response.pagination,
            });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    BrowseTitleState.prototype.loadMoreTitles = function (ctx) {
        ctx.patchState({ loading: true });
        var filters = tslib_1.__assign({}, ctx.getState().filters, { page: ctx.getState().titles.current_page + 1 });
        return this.titles.getAll(tslib_1.__assign({}, filters, { perPage: 16 })).pipe(tap(function (response) {
            var oldData = ctx.getState().titles.data.slice();
            response.pagination.data = oldData.concat(response.pagination.data);
            ctx.patchState({ titles: response.pagination });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    BrowseTitleState.prototype.updateFilters = function (ctx, action) {
        ctx.patchState({
            filters: BrowseTitleState_1.queryParamsToFilters(action.filters),
        });
    };
    BrowseTitleState.prototype.loadFilterOptions = function (ctx) {
        ctx.patchState({
            filterOptions: tslib_1.__assign({}, ctx.getState().filterOptions, { genres: this.settings.getJson('browse.genres'), certifications: this.settings.getJson('browse.ageRatings') })
        });
        this.valueLists.get(['countries', 'languages']).subscribe(function (response) {
            ctx.patchState({
                filterOptions: tslib_1.__assign({}, ctx.getState().filterOptions, { languages: response.languages, countries: response.countries })
            });
        });
    };
    var BrowseTitleState_1;
    tslib_1.__decorate([
        Action(ReloadTitles),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ReloadTitles]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "reloadTitles", null);
    tslib_1.__decorate([
        Action(LoadMoreTitles),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "loadMoreTitles", null);
    tslib_1.__decorate([
        Action(UpdateFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateFilters]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "updateFilters", null);
    tslib_1.__decorate([
        Action(LoadFilterOptions),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState.prototype, "loadFilterOptions", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "titles", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "loading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "canLoadMore", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "doesNotHaveResults", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "anyFilterActive", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "countries", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "languages", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "genres", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "certifications", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], BrowseTitleState, "filters", null);
    BrowseTitleState = BrowseTitleState_1 = tslib_1.__decorate([
        State({
            name: 'browseTitles',
            defaults: {
                loading: false,
                filters: {},
                filterOptions: {
                    genres: [],
                    certifications: [],
                    countries: [],
                    languages: [],
                }
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store,
            TitlesService,
            ValueLists,
            Settings,
            Router])
    ], BrowseTitleState);
    return BrowseTitleState;
}());
export { BrowseTitleState };
