import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Settings } from '../../core/config/settings.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
var RequestExtraCredentialsModalComponent = /** @class */ (function () {
    function RequestExtraCredentialsModalComponent(dialogRef, data, zone, settings, fb) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.zone = zone;
        this.settings = settings;
        this.fb = fb;
        this.form = this.fb.group({
            email: [''],
            password: [''],
        });
        this.errors$ = new BehaviorSubject({});
        this.onSubmit$ = new Subject();
    }
    RequestExtraCredentialsModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.zone.run(function () {
            _this.credentialsToRequest = _this.data.credentials;
        });
    };
    RequestExtraCredentialsModalComponent.prototype.shouldCredentialBeRequested = function (name) {
        return this.credentialsToRequest.indexOf(name) > -1;
    };
    RequestExtraCredentialsModalComponent.prototype.confirm = function () {
        this.onSubmit$.next(tslib_1.__assign({}, this.form.value));
    };
    RequestExtraCredentialsModalComponent.prototype.close = function () {
        this.onSubmit$.complete();
        this.dialogRef.close();
    };
    RequestExtraCredentialsModalComponent.prototype.handleErrors = function (response) {
        var _this = this;
        // we need to request user extra credentials again, for example
        // if email address user supplied previously already exists
        // we might need to request password for account with that email
        if (response['messages']['email']) {
            this.credentialsToRequest.push('password');
        }
        this.zone.run(function () {
            _this.errors$.next(response.messages);
        });
    };
    return RequestExtraCredentialsModalComponent;
}());
export { RequestExtraCredentialsModalComponent };
