import * as tslib_1 from "tslib";
import { Select } from '@ngxs/store';
import { HomepageState } from './state/homepage-state';
import { Observable } from 'rxjs';
import { Settings } from '@common/core/config/settings.service';
var HomepageComponent = /** @class */ (function () {
    function HomepageComponent(settings) {
        this.settings = settings;
    }
    tslib_1.__decorate([
        Select(HomepageState.content),
        tslib_1.__metadata("design:type", Observable)
    ], HomepageComponent.prototype, "content$", void 0);
    return HomepageComponent;
}());
export { HomepageComponent };
