import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HomepageService } from '../homepage.service';
import { ChangeSlide, ChangeSliderFocusState, LoadLists } from './homepage-state.actions';
var HomepageState = /** @class */ (function () {
    function HomepageState(homepage) {
        this.homepage = homepage;
    }
    HomepageState.sliderList = function (state) {
        return state.lists[0];
    };
    HomepageState.activeSlide = function (state) {
        return state.slider.activeSlide;
    };
    HomepageState.autoSlide = function (state) {
        return state.slider.autoSlide;
    };
    HomepageState.slideCount = function (state) {
        return state.slider.slideCount;
    };
    HomepageState.focused = function (state) {
        return state.slider.focused;
    };
    HomepageState.content = function (state) {
        return state.lists.slice(1);
    };
    HomepageState.prototype.loadLists = function (ctx) {
        if (ctx.getState().contentLoaded)
            return;
        return this.homepage.getLists().pipe(tap(function (response) {
            var sliderList = response.lists[0];
            ctx.patchState({
                lists: response.lists,
                metaTags: response.seo,
                contentLoaded: true,
                slider: tslib_1.__assign({}, ctx.getState().slider, { slideCount: sliderList ? sliderList.items.length : 0 }),
            });
        }));
    };
    HomepageState.prototype.changeSlide = function (ctx, action) {
        var lastSlide = ctx.getState().slider.slideCount - 1;
        var index = action.index;
        if (action.index > lastSlide) {
            index = 0;
        }
        else if (action.index < 0) {
            index = lastSlide;
        }
        return ctx.patchState({
            slider: tslib_1.__assign({}, ctx.getState().slider, { activeSlide: index })
        });
    };
    HomepageState.prototype.changeSliderFocusState = function (ctx, action) {
        return ctx.patchState({
            slider: tslib_1.__assign({}, ctx.getState().slider, { focused: action.state })
        });
    };
    tslib_1.__decorate([
        Action(LoadLists),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState.prototype, "loadLists", null);
    tslib_1.__decorate([
        Action(ChangeSlide),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeSlide]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState.prototype, "changeSlide", null);
    tslib_1.__decorate([
        Action(ChangeSliderFocusState),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeSliderFocusState]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState.prototype, "changeSliderFocusState", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "sliderList", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "activeSlide", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "autoSlide", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "slideCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "focused", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], HomepageState, "content", null);
    HomepageState = tslib_1.__decorate([
        State({
            name: 'homepage',
            defaults: {
                slider: {
                    activeSlide: 0,
                    autoSlide: false,
                    slideCount: 0,
                    focused: false,
                },
                contentLoaded: false,
                lists: [],
                metaTags: [],
            }
        }),
        tslib_1.__metadata("design:paramtypes", [HomepageService])
    ], HomepageState);
    return HomepageState;
}());
export { HomepageState };
