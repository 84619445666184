import * as tslib_1 from "tslib";
import { FormControl, FormGroup } from '@angular/forms';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { MEDIA_TYPE } from '../../media-type';
var CrupdateReviewModalComponent = /** @class */ (function () {
    function CrupdateReviewModalComponent(dialogRef, data, toast, reviews) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.toast = toast;
        this.reviews = reviews;
        this.errors$ = new BehaviorSubject({});
        this.loading$ = new BehaviorSubject(false);
        this.reviewForm = new FormGroup({
            body: new FormControl(''),
            score: new FormControl(null),
        });
        this.hydrateForm();
    }
    CrupdateReviewModalComponent.prototype.close = function (review) {
        this.dialogRef.close(review);
    };
    CrupdateReviewModalComponent.prototype.confirm = function () {
        var _this = this;
        this.loading$.next(true);
        var params = tslib_1.__assign({}, this.reviewForm.value, { mediaId: this.data.mediaId, mediaType: MEDIA_TYPE.TITLE });
        var observable = this.data.review ?
            this.reviews.update(this.data.review.id, this.reviewForm.value) :
            this.reviews.create(params);
        observable
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            _this.toast.open(MESSAGES.REVIEW_CREATE_SUCCESS);
            _this.close(response.review);
        }, function (errorResponse) {
            _this.errors$.next(errorResponse.messages);
        });
    };
    CrupdateReviewModalComponent.prototype.hydrateForm = function () {
        if (this.data.review) {
            this.reviewForm.patchValue(this.data.review);
        }
    };
    return CrupdateReviewModalComponent;
}());
export { CrupdateReviewModalComponent };
