import * as tslib_1 from "tslib";
import { Action, createSelector, State, Store } from '@ngxs/store';
import * as Dot from 'dot-object';
import { Settings } from '@common/core/config/settings.service';
var SetSettings = /** @class */ (function () {
    function SetSettings(settings) {
        this.settings = settings;
    }
    SetSettings.type = '[Settings] Set';
    return SetSettings;
}());
export { SetSettings };
var SettingsState = /** @class */ (function () {
    function SettingsState(settings, store) {
        this.settings = settings;
        this.store = store;
    }
    SettingsState_1 = SettingsState;
    SettingsState.setting = function (name) {
        return createSelector([SettingsState_1], function (state) {
            return Dot.pick(name, state);
        });
    };
    SettingsState.prototype.ngxsOnInit = function (ctx) {
        var _this = this;
        this.settings.all$().subscribe(function (settings) {
            _this.store.dispatch(new SetSettings(settings));
        });
    };
    SettingsState.prototype.setSettings = function (ctx, action) {
        ctx.patchState(action.settings);
    };
    var SettingsState_1;
    tslib_1.__decorate([
        Action(SetSettings),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSettings]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SettingsState.prototype, "setSettings", null);
    SettingsState = SettingsState_1 = tslib_1.__decorate([
        State({
            name: 'settings',
            defaults: {},
        }),
        tslib_1.__metadata("design:paramtypes", [Settings, Store])
    ], SettingsState);
    return SettingsState;
}());
export { SettingsState };
