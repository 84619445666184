import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth-guard.service';
import { AccountSettingsComponent } from './account-settings.component';
import { AccountSettingsResolve } from './account-settings-resolve.service';
import { CheckPermissionsGuard } from '@common/guards/check-permissions-guard.service';
var ɵ0 = { name: 'account-settings' };
var routes = [
    // TODO: reloading if already in "account/settings" ads "settings" again to url if uncommented
    // {
    //     path: 'account',
    //     pathMatch: 'full',
    //     redirectTo: 'account/settings'
    // },
    {
        path: 'account/settings',
        component: AccountSettingsComponent,
        resolve: { api: AccountSettingsResolve },
        canActivate: [AuthGuard, CheckPermissionsGuard],
        data: ɵ0,
    },
];
var AccountSettingsRoutingModule = /** @class */ (function () {
    function AccountSettingsRoutingModule() {
    }
    return AccountSettingsRoutingModule;
}());
export { AccountSettingsRoutingModule };
export { ɵ0 };
