import { Settings } from '@common/core/config/settings.service';
var StreamableLabelComponent = /** @class */ (function () {
    function StreamableLabelComponent(settings) {
        this.settings = settings;
    }
    Object.defineProperty(StreamableLabelComponent.prototype, "isHidden", {
        get: function () {
            return !this.item ||
                !this.item.stream_videos_count ||
                !this.settings.get('streaming.show_label');
        },
        enumerable: true,
        configurable: true
    });
    return StreamableLabelComponent;
}());
export { StreamableLabelComponent };
