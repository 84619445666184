import * as tslib_1 from "tslib";
import { CrupdateVideoModalComponent } from '../crupdate-video-modal/crupdate-video-modal.component';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUser } from '@common/auth/current-user';
import { AddVideo } from '../../titles/state/title-actions';
import { Modal } from '@common/core/ui/dialogs/modal.service';
import { TitleState } from '../../titles/state/title-state';
import { Settings } from '@common/core/config/settings.service';
import { MEDIA_TYPE } from '../../media-type';
var TitleVideosComponent = /** @class */ (function () {
    function TitleVideosComponent(modal, store, currentUser, settings) {
        this.modal = modal;
        this.store = store;
        this.currentUser = currentUser;
        this.settings = settings;
        this.loading$ = new BehaviorSubject(false);
    }
    TitleVideosComponent.prototype.openAddVideoModal = function () {
        var _this = this;
        var episode = this.store.selectSnapshot(TitleState.episode), title = this.store.selectSnapshot(TitleState.title);
        this.modal.open(CrupdateVideoModalComponent, 
        // title should not be changeable when adding video from any specific title pages and
        // season/episode should not be changeable when adding video from specific episode page
        {
            title: title,
            hideTitleSelect: true,
            hideEpisodeSelect: !!episode,
            episode: episode ? episode.episode_number : null,
            season: episode ? episode.season_number : null,
        }).beforeClosed().subscribe(function (video) {
            if (!video)
                return;
            _this.store.dispatch(new AddVideo(video));
        });
    };
    TitleVideosComponent.prototype.isSeries = function (title) {
        return title.type === MEDIA_TYPE.TITLE && title.is_series;
    };
    tslib_1.__decorate([
        Select(TitleState.titleOrEpisode),
        tslib_1.__metadata("design:type", Observable)
    ], TitleVideosComponent.prototype, "title$", void 0);
    tslib_1.__decorate([
        Select(TitleState.seasons),
        tslib_1.__metadata("design:type", Observable)
    ], TitleVideosComponent.prototype, "seasons$", void 0);
    return TitleVideosComponent;
}());
export { TitleVideosComponent };
