import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { LoadTitle } from './state/title-actions';
import { Store } from '@ngxs/store';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
var TitleResolverService = /** @class */ (function () {
    function TitleResolverService(store, router) {
        this.store = store;
        this.router = router;
    }
    TitleResolverService.prototype.resolve = function (route) {
        var _this = this;
        var params = tslib_1.__assign({}, route.params);
        if (route.data.fullCredits)
            params.fullCredits = true;
        return this.store.dispatch(new LoadTitle(params.titleId, params))
            .pipe(catchError(function () {
            _this.router.navigateByUrl('/');
            return EMPTY;
        }), mergeMap(function (store) {
            return of({ seo: store.title.metaTags });
        }));
    };
    TitleResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleResolverService_Factory() { return new TitleResolverService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: TitleResolverService, providedIn: "root" });
    return TitleResolverService;
}());
export { TitleResolverService };
