import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import { Toast } from '../core/ui/toast.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RecaptchaService } from '../core/services/recaptcha.service';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(settings, http, toast, router, recaptcha) {
        this.settings = settings;
        this.http = http;
        this.toast = toast;
        this.router = router;
        this.recaptcha = recaptcha;
        this.loading$ = new BehaviorSubject(false);
        this.form = new FormGroup({
            name: new FormControl(),
            email: new FormControl(),
            message: new FormControl()
        });
        this.errors$ = new BehaviorSubject({});
    }
    ContactComponent.prototype.ngOnInit = function () {
        if (this.recaptcha.enabledFor('contact')) {
            this.recaptcha.load();
        }
    };
    ContactComponent.prototype.submitMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading$.next(true);
                        _a = this.recaptcha.enabledFor('contact');
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recaptcha.verify('contact')];
                    case 1:
                        _a = !(_b.sent());
                        _b.label = 2;
                    case 2:
                        if (_a) {
                            return [2 /*return*/, this.toast.open('Could not verify you are human.')];
                        }
                        this.http.post('contact-page', this.form.value)
                            .pipe(finalize(function () {
                            _this.loading$.next(false);
                        })).subscribe(function () {
                            _this.errors$.next({});
                            _this.toast.open('Your message has been submitted.');
                            _this.router.navigate(['/']);
                        }, function (err) { return _this.errors$.next(err.messages); });
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContactComponent;
}());
export { ContactComponent };
