import { Router } from '@angular/router';
import { CurrentUser } from '../auth/current-user';
import { AuthService } from '../auth/auth.service';
import { Settings } from '@common/core/config/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "@angular/router";
import * as i3 from "../auth/auth.service";
import * as i4 from "../core/config/settings.service";
var CheckPermissionsGuard = /** @class */ (function () {
    function CheckPermissionsGuard(currentUser, router, auth, settings) {
        this.currentUser = currentUser;
        this.router = router;
        this.auth = auth;
        this.settings = settings;
    }
    CheckPermissionsGuard.prototype.canActivate = function (route, state) {
        return this.runAuthCheck(route, state);
    };
    CheckPermissionsGuard.prototype.canActivateChild = function (route, state) {
        return this.runAuthCheck(route, state);
    };
    CheckPermissionsGuard.prototype.runAuthCheck = function (route, state) {
        if (this.settings.get('billing.force_subscription') && !this.currentUser.isAdmin() && !this.currentUser.isSubscribed() && !route.data.allowIfSubscriptionForced) {
            this.router.navigate(['/billing/upgrade']);
        }
        else {
            return this.checkPermissions(route, state);
        }
    };
    CheckPermissionsGuard.prototype.getActiveRoute = function (route) {
        while (route.firstChild)
            route = route.firstChild;
        return route;
    };
    CheckPermissionsGuard.prototype.checkPermissions = function (route, state) {
        var hasPermission = true;
        if (route.data.permissions) {
            hasPermission = this.currentUser.hasPermissions(route.data.permissions);
        }
        // user can access this route, bail
        if (hasPermission)
            return true;
        // redirect to login page, if user is not logged in
        if (!this.currentUser.isLoggedIn()) {
            this.currentUser.redirectUri = state.url;
            this.router.navigate(['login']);
        }
        else {
            this.router.navigate([this.auth.getRedirectUri()]);
        }
        return hasPermission;
    };
    CheckPermissionsGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckPermissionsGuard_Factory() { return new CheckPermissionsGuard(i0.ɵɵinject(i1.CurrentUser), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.Settings)); }, token: CheckPermissionsGuard, providedIn: "root" });
    return CheckPermissionsGuard;
}());
export { CheckPermissionsGuard };
