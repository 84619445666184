import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Pages } from '../pages.service';
import { CustomPage } from '@common/core/types/models/CustomPage';
import { Settings } from '@common/core/config/settings.service';
import { BehaviorSubject } from 'rxjs';
var CustomPageComponent = /** @class */ (function () {
    function CustomPageComponent(pages, route, sanitizer, router, settings) {
        this.pages = pages;
        this.route = route;
        this.sanitizer = sanitizer;
        this.router = router;
        this.settings = settings;
        this.page$ = new BehaviorSubject(null);
        this.body$ = new BehaviorSubject(null);
        this.showNavbar = true;
    }
    Object.defineProperty(CustomPageComponent.prototype, "page", {
        set: function (page) {
            this.page$.next(page);
            this.body$.next(this.sanitizer.bypassSecurityTrustHtml(page.body));
        },
        enumerable: true,
        configurable: true
    });
    CustomPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.page$.value)
            return;
        this.route.params.subscribe(function (params) {
            var id = params.id || _this.route.snapshot.data.id;
            _this.pages.get(id).subscribe(function (response) {
                _this.page = response.page;
            }, function () {
                _this.router.navigate(['/404'], { skipLocationChange: true });
            });
        });
    };
    return CustomPageComponent;
}());
export { CustomPageComponent };
