import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var Modal = /** @class */ (function () {
    function Modal(dialog) {
        this.dialog = dialog;
    }
    Modal.prototype.open = function (component, data, config) {
        if (data === void 0) { data = {}; }
        if (config === void 0) { config = {}; }
        if (!data)
            data = {};
        if (typeof config === 'string')
            config = { panelClass: config };
        if (!Array.isArray(config.panelClass))
            config.panelClass = [config.panelClass];
        config.panelClass.push('be-modal');
        return this.dialog.open(component, tslib_1.__assign({}, config, { data: data }));
    };
    Modal.prototype.show = function (component, data) {
        if (data === void 0) { data = {}; }
        return this.open(component, data);
    };
    Modal.prototype.anyDialogOpen = function () {
        return this.dialog.openDialogs.length > 0;
    };
    Modal.prototype.closeAll = function () {
        this.dialog.closeAll();
    };
    Modal.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Modal_Factory() { return new Modal(i0.ɵɵinject(i1.MatDialog)); }, token: Modal, providedIn: "root" });
    return Modal;
}());
export { Modal };
