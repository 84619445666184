import * as tslib_1 from "tslib";
import { Select, Store } from '@ngxs/store';
import { AddToWatchlist, RemoveFromWatchlist } from '../../../lists/user-lists/state/user-lists-state-actions';
import { UserListsState } from '../../../lists/user-lists/state/user-lists-state';
import { Observable } from 'rxjs';
var TitleActionButtonsComponent = /** @class */ (function () {
    function TitleActionButtonsComponent(store) {
        this.store = store;
    }
    TitleActionButtonsComponent.prototype.isInWatchlist = function () {
        var _this = this;
        var watchlist = this.store.selectSnapshot(UserListsState.watchlist);
        if (!watchlist)
            return false;
        return watchlist.items.findIndex(function (i) { return i.id === _this.item.id && i.type === _this.item.type; }) > -1;
    };
    TitleActionButtonsComponent.prototype.addToWatchlist = function () {
        this.store.dispatch(new AddToWatchlist(this.item));
    };
    TitleActionButtonsComponent.prototype.removeFromWatchlist = function () {
        this.store.dispatch(new RemoveFromWatchlist(this.item));
    };
    tslib_1.__decorate([
        Select(UserListsState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], TitleActionButtonsComponent.prototype, "loading$", void 0);
    return TitleActionButtonsComponent;
}());
export { TitleActionButtonsComponent };
