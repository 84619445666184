import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { TITLE_STATE_MODEL_DEFAULTS } from './title-state-model-defaults';
import { Router } from '@angular/router';
import { AddVideo, CrupdateReview, DeleteReview, DeleteVideo, LoadRelatedTitles, LoadReviews, LoadTitle, RateVideo, ReportVideo, SetTitle, UpdateVideo } from './title-actions';
import { TitlesService } from '../titles.service';
import { tap } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { objectsAreEqual } from '@common/core/utils/objects-are-equal';
import { VideoService } from '../../videos/video.service';
import { Toast } from '@common/core/ui/toast.service';
import { SettingsState } from '../../settings-state';
var TitleState = /** @class */ (function () {
    function TitleState(router, store, titles, reviews, videos, toast) {
        this.router = router;
        this.store = store;
        this.titles = titles;
        this.reviews = reviews;
        this.videos = videos;
        this.toast = toast;
    }
    TitleState_1 = TitleState;
    TitleState.backdrop = function (state) {
        return state.title.backdrop;
    };
    TitleState.videoCoverImage = function (state) {
        var episodeImage = state.episode && state.episode.poster, titleImage = state.title.images[0];
        var image = episodeImage || titleImage || state.title.backdrop;
        if (typeof image !== 'string') {
            image = image.url;
        }
        return image || null;
    };
    TitleState.primaryVideo = function (state, preferFull) {
        if (preferFull) {
            return state.title.videos.find(function (video) { return video.category === 'full' && video.type !== 'external'; });
        }
        else {
            return state.title.videos.find(function (video) { return video.category !== 'full' && video.type !== 'external'; });
        }
    };
    TitleState.videos = function (state, category) {
        switch (category) {
            case 'full':
                return state.videos.filter(function (v) { return v.category === 'full'; });
            case 'short':
                return state.videos.filter(function (v) { return v.category !== 'full'; });
            case 'trailer':
                return state.videos.filter(function (v) { return v.category === 'trailer'; });
            case 'clip':
                return state.videos.filter(function (v) { return v.category === 'clip'; });
            default:
                return state.videos;
        }
    };
    TitleState.loading = function (state) {
        return state.loading;
    };
    TitleState.episodes = function (state) {
        return state.episodes || state.title.season.episodes;
    };
    TitleState.episode = function (state) {
        return state.episode;
    };
    TitleState.title = function (state) {
        return state.title;
    };
    TitleState.seasons = function (state) {
        return state.title.seasons;
    };
    TitleState.season = function (state) {
        return state.season;
    };
    TitleState.reviews = function (state) {
        return state.title.reviews;
    };
    TitleState.relatedTitles = function (state) {
        return state.related;
    };
    TitleState.titleOrEpisodeCredits = function (state) {
        if (state.episode) {
            return state.season.credits.concat(state.episode.credits);
        }
        else {
            return state.title.credits;
        }
    };
    TitleState.titleOrEpisodeCast = function (state, credits) {
        return credits.filter(function (person) { return person.pivot.department === 'cast'; });
    };
    TitleState.titleOrEpisode = function (state) {
        return state.episode || state.title;
    };
    TitleState.currentEpisode = function (state) {
        return state.current_episode;
    };
    TitleState.nextEpisode = function (state) {
        return state.next_episode;
    };
    TitleState.metaTags = function (state) {
        return state.metaTags;
    };
    TitleState.prototype.loadTitle = function (ctx, action) {
        var state = ctx.getState();
        // already have this title loaded and no query params changed, can bail
        if (objectsAreEqual(action.params, state.titleQueryParams, false))
            return;
        return this.titles.get(action.titleId, action.params).pipe(tap(function (response) {
            ctx.dispatch(new SetTitle(response, action.params));
        }));
    };
    TitleState.prototype.setTitle = function (ctx, action) {
        var response = action.response;
        var newState = {
            title: action.response.title,
            titleQueryParams: action.params,
            episode: null,
            season: null,
            current_episode: null,
            next_episode: null,
            metaTags: response.seo,
            videos: [],
        };
        if (action.params.episodeNumber) {
            newState.episode = response.title.season.episodes.find(function (ep) {
                return ep.episode_number === +action.params.episodeNumber;
            });
        }
        if (action.params.seasonNumber) {
            newState.season = response.title.season;
        }
        if (response.current_episode && response.next_episode) {
            newState.current_episode = response.current_episode;
            newState.next_episode = response.next_episode;
        }
        newState.videos = newState.title.videos;
        ctx.patchState(newState);
    };
    TitleState.prototype.loadRelatedTitles = function (ctx) {
        return this.titles.getRelatedTitles(ctx.getState().title, { limit: 5 }).pipe(tap(function (response) {
            ctx.patchState({ related: response.titles });
        }));
    };
    TitleState.prototype.loadReviews = function (ctx) {
        // reviews are already loaded
        if (ctx.getState().title.reviews)
            return;
        var params = {
            titleId: ctx.getState().title.id,
            limit: 35,
            withTextOnly: true,
            with: 'user',
        };
        return this.reviews.getAll(params).pipe(tap(function (response) {
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, { reviews: response.pagination.data })
            });
        }));
    };
    TitleState.prototype.crupdateReview = function (ctx, action) {
        var oldReviews = ctx.getState().title.reviews.slice();
        var index = oldReviews.findIndex(function (r) { return r.id === action.review.id; });
        if (index > -1) {
            oldReviews[index] = action.review;
        }
        else {
            oldReviews.push(action.review);
        }
        ctx.patchState({
            title: tslib_1.__assign({}, ctx.getState().title, { reviews: oldReviews })
        });
    };
    TitleState.prototype.deleteReview = function (ctx, action) {
        return this.reviews.delete([action.review.id]).pipe(tap(function () {
            var newReviews = ctx.getState().title.reviews.filter(function (curr) { return curr.id !== action.review.id; });
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, { reviews: newReviews })
            });
        }));
    };
    TitleState.prototype.addVideo = function (ctx, action) {
        ctx.patchState({ videos: ctx.getState().videos.concat([action.video]) });
    };
    TitleState.prototype.updateVideo = function (ctx, action) {
        var videos = ctx.getState().videos.map(function (video) {
            if (video.id === action.video.id) {
                return action.video;
            }
            else {
                return video;
            }
        });
        ctx.patchState({ videos: videos });
    };
    TitleState.prototype.rateVideo = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.rate(action.video.id, action.rating)
            .pipe(tap(function (response) {
            var videos = ctx.getState().videos.map(function (v) {
                v = tslib_1.__assign({}, v);
                if (v.id === action.video.id) {
                    v.positive_votes = response.video.positive_votes;
                    v.negative_votes = response.video.negative_votes;
                }
                return v;
            });
            ctx.patchState({ videos: videos, loading: false });
        }, function () { return ctx.patchState({ loading: false }); }));
    };
    TitleState.prototype.deleteVideo = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.videos.delete([action.video.id])
            .pipe(tap(function () {
            var videos = ctx.getState().videos.filter(function (v) { return v.id !== action.video.id; });
            ctx.patchState({ videos: videos, loading: false });
            _this.toast.open('Video deleted.');
        }, function () { return ctx.patchState({ loading: false }); }));
    };
    TitleState.prototype.reportVideo = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.videos.report(action.video.id)
            .pipe(tap(function () {
            ctx.patchState({ loading: false });
            _this.toast.open('Video reported.');
        }, function () {
            ctx.patchState({ loading: false });
            _this.toast.open('You have already reported this video.');
        }));
    };
    var TitleState_1;
    tslib_1.__decorate([
        Action(LoadTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, LoadTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadTitle", null);
    tslib_1.__decorate([
        Action(SetTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "setTitle", null);
    tslib_1.__decorate([
        Action(LoadRelatedTitles),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadRelatedTitles", null);
    tslib_1.__decorate([
        Action(LoadReviews),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadReviews", null);
    tslib_1.__decorate([
        Action(CrupdateReview),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CrupdateReview]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "crupdateReview", null);
    tslib_1.__decorate([
        Action(DeleteReview),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteReview]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "deleteReview", null);
    tslib_1.__decorate([
        Action(AddVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "addVideo", null);
    tslib_1.__decorate([
        Action(UpdateVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "updateVideo", null);
    tslib_1.__decorate([
        Action(RateVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, RateVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "rateVideo", null);
    tslib_1.__decorate([
        Action(DeleteVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "deleteVideo", null);
    tslib_1.__decorate([
        Action(ReportVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ReportVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "reportVideo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "backdrop", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "videoCoverImage", null);
    tslib_1.__decorate([
        Selector([SettingsState.setting('streaming.prefer_full')]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Boolean]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "primaryVideo", null);
    tslib_1.__decorate([
        Selector([SettingsState.setting('streaming.video_panel_content')]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, String]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "videos", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "loading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "episodes", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "episode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "title", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "seasons", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "season", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "reviews", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "relatedTitles", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "titleOrEpisodeCredits", null);
    tslib_1.__decorate([
        Selector([TitleState_1.titleOrEpisodeCredits]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "titleOrEpisodeCast", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "titleOrEpisode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "currentEpisode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "nextEpisode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "metaTags", null);
    TitleState = TitleState_1 = tslib_1.__decorate([
        State({
            name: 'title',
            defaults: TITLE_STATE_MODEL_DEFAULTS,
        }),
        tslib_1.__metadata("design:paramtypes", [Router,
            Store,
            TitlesService,
            ReviewService,
            VideoService,
            Toast])
    ], TitleState);
    return TitleState;
}());
export { TitleState };
