import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { Reset, SearchEverything, SetQuery } from './search-state-actions';
import { SearchService } from '../search.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MEDIA_TYPE } from '../../media-type';
var SearchState = /** @class */ (function () {
    function SearchState(search) {
        this.search = search;
    }
    SearchState.results = function (state) {
        return state.results;
    };
    SearchState.movies = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.TITLE && !result.is_series; });
    };
    SearchState.series = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.TITLE && result.is_series; });
    };
    SearchState.people = function (state) {
        return state.results.filter(function (result) { return result.type === MEDIA_TYPE.PERSON; });
    };
    SearchState.query = function (state) {
        return state.query;
    };
    SearchState.prototype.searchEverything = function (ctx, _a) {
        var query = _a.query, type = _a.type, limit = _a.limit;
        ctx.patchState({ query: query });
        if (!query || query.length < 3) {
            ctx.patchState({ results: [] });
            return of({ results: [] });
        }
        return this.search.everything(query, { type: type, limit: limit }).pipe(tap(function (response) {
            ctx.patchState({ results: response.results });
        }));
    };
    SearchState.prototype.setQuery = function (ctx, action) {
        ctx.patchState({ query: action.query });
    };
    SearchState.prototype.reset = function (ctx) {
        ctx.patchState({ results: [], query: '' });
    };
    tslib_1.__decorate([
        Action(SearchEverything),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SearchEverything]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState.prototype, "searchEverything", null);
    tslib_1.__decorate([
        Action(SetQuery),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetQuery]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState.prototype, "setQuery", null);
    tslib_1.__decorate([
        Action(Reset),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState.prototype, "reset", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState, "results", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState, "movies", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState, "series", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState, "people", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SearchState, "query", null);
    SearchState = tslib_1.__decorate([
        State({
            name: 'search',
            defaults: {
                results: [],
                query: '',
            }
        }),
        tslib_1.__metadata("design:paramtypes", [SearchService])
    ], SearchState);
    return SearchState;
}());
export { SearchState };
