import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { TitleUrlsService } from '../../title-urls.service';
import { map } from 'rxjs/operators';
var FullCreditsPageComponent = /** @class */ (function () {
    function FullCreditsPageComponent(store, urls) {
        this.store = store;
        this.urls = urls;
        this.groupedCredits$ = this.store.select(TitleState.titleOrEpisodeCredits)
            .pipe(map(this.groupCredits));
    }
    FullCreditsPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.store.dispatch(new ToggleGlobalLoader(false)); });
    };
    FullCreditsPageComponent.prototype.trackByFn = function (title) {
        return title.id;
    };
    FullCreditsPageComponent.prototype.groupCredits = function (credits) {
        return credits.reduce(function (h, a) {
            var _a;
            return Object.assign(h, (_a = {}, _a[a.pivot.department] = (h[a.pivot.department] || []).concat(a), _a));
        }, {});
    };
    tslib_1.__decorate([
        Select(TitleState.title),
        tslib_1.__metadata("design:type", Observable)
    ], FullCreditsPageComponent.prototype, "title$", void 0);
    tslib_1.__decorate([
        Select(TitleState.backdrop),
        tslib_1.__metadata("design:type", Observable)
    ], FullCreditsPageComponent.prototype, "backdropImage$", void 0);
    return FullCreditsPageComponent;
}());
export { FullCreditsPageComponent };
