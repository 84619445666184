import { Settings } from '../config/settings.service';
import { LazyLoaderService } from '../utils/lazy-loader.service';
import { AppHttpClient } from '../http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../http/app-http-client.service";
import * as i3 from "../utils/lazy-loader.service";
var RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';
var RecaptchaService = /** @class */ (function () {
    function RecaptchaService(settings, http, lazyLoader) {
        this.settings = settings;
        this.http = http;
        this.lazyLoader = lazyLoader;
    }
    RecaptchaService.prototype.enabledFor = function (page) {
        return this.settings.get('recaptcha.site_key') &&
            this.settings.get('recaptcha.secret_key') &&
            this.settings.get("recaptcha.enable_for_" + page);
    };
    RecaptchaService.prototype.load = function () {
        return this.lazyLoader
            .loadAsset(RECAPTCHA_URL + this.settings.get('recaptcha.site_key'), { type: 'js' });
    };
    RecaptchaService.prototype.verify = function (page) {
        var _this = this;
        return new Promise(function (resolve) {
            if (!grecaptcha)
                return false;
            grecaptcha.ready(function () {
                grecaptcha.execute(_this.settings.get('recaptcha.site_key'), { action: page })
                    .then(function (token) {
                    _this.http.post('recaptcha/verify', { token: token })
                        .subscribe(function (response) { return resolve(response.success); });
                });
            });
        });
    };
    RecaptchaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecaptchaService_Factory() { return new RecaptchaService(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.AppHttpClient), i0.ɵɵinject(i3.LazyLoaderService)); }, token: RecaptchaService, providedIn: "root" });
    return RecaptchaService;
}());
export { RecaptchaService };
