import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { CommandTypes } from './commands/command-types';
import { createSelectorBox } from './utils/create-selector-box';
import { selectNode } from './utils/select-node';
import { deselectNode } from './utils/deselect-node';
import { filter } from 'rxjs/operators';
import { Toast } from '../../core/ui/toast.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/ui/toast.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/config/settings.service";
export var APPEARANCE_TOKEN = 't50b4BT5hWsvJMr7';
var AppearanceListenerService = /** @class */ (function () {
    function AppearanceListenerService(toast, router, settings) {
        this.toast = toast;
        this.router = router;
        this.settings = settings;
        this.active = false;
        this.dom = {};
    }
    AppearanceListenerService.prototype.init = function () {
        this.active = window.location.search.includes("be-preview-mode=" + APPEARANCE_TOKEN);
        if (!this.active)
            return;
        this.listenForMessages();
        this.blockNotAllowedRoutes();
        this.createDomNodes();
        window.parent.postMessage(APPEARANCE_TOKEN, '*');
    };
    AppearanceListenerService.prototype.listenForMessages = function () {
        var _this = this;
        window.addEventListener('message', function (e) {
            if (_this.isAppearanceEvent(e) && _this.eventIsTrusted(e)) {
                _this.handleCommand(e.data);
            }
        });
    };
    AppearanceListenerService.prototype.handleCommand = function (command) {
        // TODO: fix command data typings (remove "any")
        switch (command.type) {
            case (CommandTypes.Navigate):
                return this.router.navigate([command.route]);
            case (CommandTypes.SetConfig):
                return this.settings.set(command.key, command.value);
            case (CommandTypes.Select):
                return selectNode(command.selector, command.index, this.dom.selectBox);
            case (CommandTypes.Deselect):
                return deselectNode(this.dom.selectBox);
            case (CommandTypes.SetColors):
                return this.dom.colors.setProperty(command.key, command.value);
            case (CommandTypes.SetCustomCss):
                return this.renderCustomCode('css', command.content);
            case (CommandTypes.SetCustomHtml):
                return this.renderCustomCode('html', command.content);
        }
    };
    AppearanceListenerService.prototype.renderCustomCode = function (type, content) {
        var parent = type === 'html' ? document.body : document.head, nodeType = type === 'html' ? 'div' : 'style';
        var customNode = parent.querySelector('#be-custom-css');
        if (!customNode) {
            customNode = document.createElement(nodeType);
            customNode.id = 'be-custom-css';
            parent.appendChild(customNode);
        }
        return customNode.innerHTML = content;
    };
    AppearanceListenerService.prototype.eventIsTrusted = function (e) {
        return (new URL(e.origin).hostname) === window.location.hostname;
    };
    AppearanceListenerService.prototype.isAppearanceEvent = function (e) {
        var data = e.data;
        return data && (data.type in CommandTypes);
    };
    AppearanceListenerService.prototype.createDomNodes = function () {
        this.dom.selectBox = createSelectorBox();
        this.dom.colors = document.documentElement.style;
    };
    AppearanceListenerService.prototype.blockNotAllowedRoutes = function () {
        var _this = this;
        var config = this.settings.get('vebto.admin.appearance');
        this.router.events
            .pipe(filter(function (e) { return e.toString().indexOf('NavigationStart') === 0; }))
            .subscribe(function (e) {
            if (e.url === '/')
                return;
            // route exists in config, bail
            var routes = config.navigationRoutes;
            if (routes.find(function (route) { return e.url.indexOf(route) > -1; }))
                return;
            // prevent navigation to routes not specified in config
            var current = _this.router.url.split('?')[0];
            _this.router.navigate([current], { queryParamsHandling: 'preserve' });
            setTimeout(function () { return _this.toast.open('That page is not supported by the editor.'); });
        });
    };
    AppearanceListenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppearanceListenerService_Factory() { return new AppearanceListenerService(i0.ɵɵinject(i1.Toast), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Settings)); }, token: AppearanceListenerService, providedIn: "root" });
    return AppearanceListenerService;
}());
export { AppearanceListenerService };
