import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CurrentUser } from '../../../../common/auth/current-user';
import { Select, Store } from '@ngxs/store';
import { LoadUserLists } from './state/user-lists-state-actions';
import { UserListsState } from './state/user-lists-state';
import { Observable } from 'rxjs';
var UserListsComponent = /** @class */ (function () {
    function UserListsComponent(store, currentUser) {
        this.store = store;
        this.currentUser = currentUser;
    }
    UserListsComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new LoadUserLists());
    };
    tslib_1.__decorate([
        Select(UserListsState.lists),
        tslib_1.__metadata("design:type", Observable)
    ], UserListsComponent.prototype, "lists$", void 0);
    tslib_1.__decorate([
        Select(UserListsState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], UserListsComponent.prototype, "loading$", void 0);
    return UserListsComponent;
}());
export { UserListsComponent };
