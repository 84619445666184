import { makeUploadPayload } from '../core/utils/make-upload-payload';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
var UploadsApiService = /** @class */ (function () {
    function UploadsApiService(http) {
        this.http = http;
    }
    UploadsApiService.prototype.getFileContents = function (file) {
        return this.http.get('uploads/' + file.id, null, { responseType: 'text' });
    };
    UploadsApiService.prototype.delete = function (params) {
        return this.http.delete('uploads', params);
    };
    UploadsApiService.prototype.upload = function (file, config) {
        if (config === void 0) { config = {}; }
        return this.http.postWithProgress(config.uri || 'uploads', makeUploadPayload(file, config.httpParams));
    };
    UploadsApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadsApiService_Factory() { return new UploadsApiService(i0.ɵɵinject(i1.AppHttpClient)); }, token: UploadsApiService, providedIn: "root" });
    return UploadsApiService;
}());
export { UploadsApiService };
